<template>
  <div class="row m-0 align-items-center borders py-md-2 py-4 font15" >
    <div class="col-12">
      <div class="row m-0 align-items-center" >
        <!--IMAGE-->
        <div class="col-12 col-md-auto text-center" v-if="projectDetails">
          <Image :imageName="projectDetails.imageUrl" width="40" theClass="item_img" v-if="projectDetails.imageUrl"/>
          <!-- <img :src="url + '/static/uploads/images/' + projectDetails.imageUrl"  class="item_img" v-if="projectDetails.imageUrl"> -->
          <img src="../assets/images/default.png"  class="item_img" v-else>
        </div>
        <!--END IMAGE-->
        <div class="col-12 col-md text-center text-md-start mt-2 mt-md-0">
          <div class="row m-0">
            <div class="col-md-12 p-0 green-text bold">
              <span v-if="item.amount"></span>
              <span v-if="projectDetails">
                <span v-if="projectDetails.name">
                  {{projectDetails.name}}
                </span>
                <span v-else>
                  {{projectDetails.description}}
                </span>
                <span v-if="projectDetails.location">
                  <span v-if="projectDetails.location.name"> | {{projectDetails.location.name}}</span>
                </span>
              </span>
            </div>
            <div class="col-md-12 p-0 font12">
              <span v-for="(type, index) in dAmount" :key="index">
                {{ item.currency }} {{ parseFloat(Math.round(type.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}} {{ type.type.donationType.description }}
                <span v-if="dAmount.length > 1 && index + 1 < dAmount.length"> | </span>
              </span>
            </div>
            <div class="col-md-12 p-0 font12" v-if="item.plaqueDescription">
              <div class="row mt-2 justify-content-center justify-content-md-start">
                <div class="col-11 col-md-auto mb-1">
                  {{item.plaqueDescription}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col text-center md-text-end bold mt-3 mt-md-0 green-text col-md-auto" >
          {{ item.currency }}{{displayTotal}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'CartItemAddPopup',
  props: ['item', 'itemKey'],
  components: {
    Image: defineAsyncComponent(() => import('./Image.vue'))
  },
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects',
      'contentProjects'
    ]),
    projectDetails () {
      const adminProject = this.adminProjects.find(project => project.projectID === this.item.projectId)
      const contentProject = this.contentProjects.find(project => project.adminProjectId === this.item.projectId)
      if (contentProject) {
        const mergedProject = { ...adminProject, ...contentProject }
        return mergedProject
      } else {
        return adminProject
      }
    },
    dAmount () {
      return this.item.donationTypes.filter(type => {
        if (type.amount === 0 || type.amount === '0' || type.amount === null) {
          return false
        }
        return true
      })
    },
    displayTotal () {
      return parseFloat(Math.round(this.item.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }
}
</script>

<style scoped>
.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
</style>
